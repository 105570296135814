<template>
   <v-card tile style="background-color:transparent;" class="pa-0 ma-0">
      <v-card-title class="pt-1 pb-0 px-4 pr-3 pn_header text-uppercase">

         <div class="">
            <div class="header-clock">
               <b>{{clockStr}}</b>
            </div>
            <div class="header-date">
               {{dateStr}}
            </div>
         </div>

         <v-spacer/>
         {{title}}
         <v-spacer/>

         <v-img
            style="height:64px;max-width:64px;"
            src="/imgs/parking.png" contain/>
      </v-card-title>
   </v-card>
</template>

<script>
export default {
   data () {
      return {
         title: "Hệ thống quản lý giữ xe PONO",
         clockStr: this.$moment().format('HH:mm'),
         dateStr: this.$moment().format('DD/MM/YY'),
         fiveSecTimer: null,
      }
   },
   async created () {
      let self = this
      this.fiveSecTimer = setInterval(function() {
         self.clockStr = self.$moment().format('HH:mm')
         self.dateStr = self.$moment().format('DD/MM/YY')
      }, 5000)
   },
   destroyed() {
      clearInterval(this.fiveSecTimer)
   },
}
</script>

<style lang="scss">
   .pn_header {
      font-size: 3vmin !important;
      font-weight: 600 !important;
      color: #EFEFEF;
      height: $header-height;
   }

   .header-clock {
      font-size: 3.3vmin !important;
      font-weight: 550;
      line-height: 1;
   }

   .header-date {
      font-size: 2.2vmin !important;
      font-weight: 400 !important;
      line-height: 1;
   }

</style>